<template>
  <div class="home">
    <div class="vipTitle">会员</div>
    <img class="vipBanner" :src="vipPics.vipHeadImg" />
    <div class="tab-container">
      <button
        v-for="(item, index) in vipPics.vipGroupList"
        :key="index"
        :class="{ active: isActiveTab(index) }"
        @click="setActiveTab(index)"
      >
        {{ item.vipName }}
      </button>
    </div>
    <img
      style="font-size: 12px; width: 90%; margin: 15px auto 0"
      class="vipBanner"
      :src="yuakalist.buyImg"
    />

    <div class="input_img-yueka">
      <div
        style=""
        :class="active == index ? 'activeyue bg' : 'bg'"
        v-for="(item, index) in yuakalist.vipModelList"
        :key="index"
        @click="activeClick(item, index)"
      >
        <span class="input_img-yueka-year">{{
          item.vipType == 1 ? "月会员卡" : "连续包月"
        }}</span>
        <div class="input_img-yueka-num">
          <span style="font-size: 16px; position: relative; left: 10px">￥</span>
          <span> {{ item.price }}</span>
        </div>
      </div>
    </div>
    <div
      style="font-size: 12px; width: 90%; margin: 15px auto 0"
      v-if="vipobj.vipType != 1"
    >
      到期按{{ vipobj.price }}元/月每月续费，可随时取消。
    </div>
    <div style="font-size: 12px; width: 90%; margin: 15px auto 0">
      <span style="vertical-align: text-bottom;"><input type="checkbox" v-model="isChecked"></span>
      <span @click="urlOpen('https://books.chinaso.com/page/yinsi1.html')"
        >《国搜智慧书城会员协议》</span
      ><span style="line-height:18px;" @click="urlOpen('https://books.chinaso.com/page/yinsi2.html')"
        >《国搜智慧书城连续包月服务协议》</span
      >
    </div>
    <div class="vip-buy">
      <div>
        <div>
          <span style="color: #7e7e7e">合计：</span>
          <span style="color: #ec351e; font-weight: 600">￥</span>
          <span style="color: #ec351e; font-size: 22px; font-weight: 600">
            {{ vipobj.price }}</span
          >
        </div>
        <div style="font-size: 14px; color: black; font-weight: 600; margin-top: 3px">
          {{ vipobj.vipType == 1 ? "开通单次月卡" : "开通连续包月" }}
        </div>
      </div>
      <img
        style="width: 60%"
        @click="getVipUrl(vipobj.buyUrl, vipobj)"
        src="../assets/vipbtn.png"
        alt=""
      />
    </div>
    <img :src="vipobj.detailImg" />
    <FootTab></FootTab>
  </div>
</template>

<script>
import FootTab from "@/components/FootTab.vue";
import wx from "weixin-js-sdk";
import axios from "axios";
var gzhstate;
export default {
  name: "home",
  components: {
    FootTab,
  },
  data() {
    return {
      title: "",
      userMes: [],
      vipPics: {
        vipModelList: [{ buyCount: 0 }],
      },

      isLiantong: true,

      isShowHasbuy: true,

      tabs: [
        { title: "黄金会员", content: "这是第一个内容" },
        { title: "白金会员", content: "这是第二个内容" },
      ],
      activeTab: 0,
      yuakalist: [],
      active: 0,
      vipobj: {},
      isChecked:false,
    };
  },
  created() {},
  mounted() {
    // 公众号支付
    gzhstate = this.AllgetQueryId("state");
    if (gzhstate) {
      this.wxgzh();
      sessionStorage.setItem("gzhstate", gzhstate);
    }
    // 获取用户信息
    this.http.getUserMes().then((res) => {
      if (res.data.status == 1) {
        this.userMes = res.data.data;
      }
    });
    // 获取2个Vip
    this.http.getVipList().then((res) => {
      this.vipPics = res.data.data;
      this.yuakalist = res.data.data.vipGroupList[0];
      this.vipobj = res.data.data.vipGroupList[0].vipModelList[0];
    });

    if (eval("(" + localStorage.getItem("channelMes") + ")").id == 5) {
      this.isShowHasbuy = false;
    }
  },
  methods: {
    wxgzh() {
      let than = this;
      let code = than.AllgetQueryId("code"); //去地址栏拿code
      let url = window.location.href;
      if (code == null) {
        this.http.getCode({ redirectUri: url }).then((res) => {
          window.location.href = res.data.data;
        });
      } else {
        this.http.userOpenid({ code: code }).then((res) => {});
      }
    },
    urlOpen(url) {
      window.open(url);
    },
    // 产品切换
    activeClick(item, index) {
      this.vipobj = item;
      this.active = index;
    },
    // 会员切换
    setActiveTab(index) {
      this.active = 0;
      this.activeTab = index;
      this.yuakalist = this.vipPics.vipGroupList[index];
      this.vipobj = this.vipPics.vipGroupList[index].vipModelList[0];
    },
    isActiveTab(index) {
      return this.activeTab == index;
    },
    playAudio() {
      this.isPlay = true;
      const music = this.$refs.player; // 音频所在对象
      music.play(); // 暂停音乐
    },
    getVipUrl(url, item) {
      if (item.productId == "") {
        this.$message({
          showClose: true,
          message: "暂未开通",
          type: "error",
        });
        return;
      }
      if (this.isChecked == false) {
        this.$message({
          showClose: true,
          message: "请勾选协议",
          type: "error",
        });
        return;
      }
      let buyImg = url;
      const currentProtocol = window.location.protocol;
      const updatedBuyImg = buyImg.replace(/^https?:/, currentProtocol);
      let that = this;
      var state = false; //是否嵌套在小程序里
      wx.miniProgram.getEnv(function (res) {
        if (res.miniprogram == true) {
          state = true;
        }
      });
      if (!this.isLiantong) {
        return;
      }
      if (this.userMes.phone) {
        // 获取vip地址
        this.http
          .getVipUrl({
            redirectUri: updatedBuyImg,
          })
          .then((res) => {
            if (res.data.status == 1) {
              gzhstate = sessionStorage.getItem("gzhstate");
              let dataA = {
                userId: this.userMes.id,
                wxPayModel: 2, //1是h5 2是微信
              };
              if (state == true) {
                dataA.wxPayModel = 2;
              } else {
                if (gzhstate) {
                  dataA.wxPayModel = 3;
                } else {
                  dataA.wxPayModel = 1;
                }
              }
              console.log(gzhstate);
              axios
                .get(res.data.msg, { params: dataA })
                .then((response) => {
                  if (response.data.status == 1) {
                    var sta = "";
                    // 小程序支付
                    if (state == true) {
                      if (response.data.data.appid) {
                        // 连续包月
                        sta =
                          "?appid=" +
                          response.data.data.appid +
                          "&contract_code=" +
                          response.data.data.contract_code +
                          "&contract_display_account=" +
                          encodeURIComponent(
                            response.data.data.contract_display_account
                          ) +
                          "&mch_id=" +
                          response.data.data.mch_id +
                          "&notify_url=" +
                          encodeURIComponent(response.data.data.notify_url) +
                          "&plan_id=" +
                          response.data.data.plan_id +
                          "&request_serial=" +
                          response.data.data.request_serial +
                          "&sign=" +
                          response.data.data.sign +
                          "&timestamp=" +
                          response.data.data.timestamp;
                      } else {
                        //单笔支付
                        sta =
                          "?timeStamp=" +
                          response.data.data.timeStamp +
                          "&nonceStr=" +
                          response.data.data.nonceStr +
                          "&package=" +
                          encodeURIComponent(response.data.data.package) +
                          "&signType=" +
                          response.data.data.signType +
                          "&paySign=" +
                          encodeURIComponent(response.data.data.paySign);
                      }
                      // 跳转到微信小程序环境内
                      wx.miniProgram.navigateTo({ url: "/pages/pay/pay" + sta });
                    } else {
                      if (gzhstate) {
                        // 公众号支付
                        console.log(response, "公众号环境");
                        if (item.vipType == 1) {
                          //单次
                          WeixinJSBridge.invoke(
                            "getBrandWCPayRequest",
                            {
                              appId: response.data.data.appId, //公众号ID，由商户传入
                              timeStamp: response.data.data.timeStamp, //时间戳，自1970年以来的秒数
                              nonceStr: response.data.data.nonceStr, //随机串
                              package: response.data.data.package,
                              signType: response.data.data.signType, //微信签名方式：
                              paySign: response.data.data.paySign,
                            },
                            function (res) {
                              if (res.err_msg == "get_brand_wcpay_request:ok") {
                                that.$message({
                                  showClose: true,
                                  message: '支付成功',
                                  type: "error",
                                });
                                // 使用以上方式判断前端返回,微信团队郑重提示：
                                //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                              } else if ( res.err_msg == "get_brand_wcpay_request:cancel" ) {
                                that.$message({
                                  showClose: true,
                                  message: '取消支付',
                                  type: "error",
                                });
                              } else {
                                that.$message({
                                  showClose: true,
                                  message: '支付失败',
                                  type: "error",
                                });
                              }
                            }
                          );
                        } else {
                          // 包月
                          window.location.href =response.data.data.orderUrl
                        }
                      } else {
                        // h5支付
                        window.location.href = response.data.data.orderUrl;
                      }
                    }
                  } else {
                    that.$message({
                      showClose: true,
                      message: response.data.msg,
                      type: "error",
                    });
                  }
                })
                .catch((error) => {
                  // 处理错误
                  //   console.error("失败：", error);
                });
              // this.http.zhifuVipUrl(res.data.msg,dataA).then((res) => {
              // 		console.log(res,"zhifu55555");
              // })
              // 	vipCode:item.vipCode,
              // requrl:updatedBuyImg,
              // userId:this.userMes.id,
              // wxPayModel:2,
              // window.location.href = res.data.msg + "&userId=" + this.userMes.id;
            } else {
              that.$message({
                showClose: true,
                message: res.data.msg,
                type: "error",
              });
            }
          });
      } else {
        if (state == true) {
          //   console.log("我在小程序中");
          wx.miniProgram.navigateTo({ url: "/pages/login/login" });
        } else {
          //   console.log("我在网页中");
          sessionStorage.setItem("loginReturn", window.location.href);
          that.$router.push({ name: "login" });
        }
      }
    },
    AllgetQueryId(variable) {
      var reg = new RegExp("(^|&)" + variable + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return decodeURI(r[2]);
      }
      return null;
    },
  },
};
</script>

<style scoped lang="scss">
.vip-buy {
  width: 90%;
  height: 80px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tab-container {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

button {
  background-color: #e2e1e1;
  color: black;
  padding: 10px 20px;
  border: none;
  margin: 0 0px;
  border-radius: 5px;
}

button.active {
  background-color: #cc9e74;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: opacity 0.3s ease;
}

.tab-content {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
}

.tab-content.show {
  opacity: 1;
  max-height: unset;
}
.home {
  background-color: #fff;
  min-height: 100vh;
  padding-bottom: 1.46rem;
  text-align: left;

  .vipTitle {
    font-size: 0.38rem;
    text-align: center;
    font-weight: bold;
    height: 0.9rem;
    line-height: 0.9rem;
  }

  .eachVip {
    margin-top: 0.1rem;
    position: relative;

    .hasBuy {
      font-size: 0.2rem;
      color: #444240;
      position: absolute;
      top: 5rem;
      left: 5.31rem;
    }
    .toDetail {
      width: 1.55rem;
      height: 0.56rem;
      position: absolute;
      top: 1.47rem;
      right: 0.67rem;
    }
  }
}
.activeyue {
  background-image: url("../assets/vip1.png") !important;
  background-size: contain !important;
  border: 2px solid #d5401d !important;
  .input_img-yueka-num {
    color: #764a03 !important;
  }
}
.input_img {
  margin: auto;
  width: 90%;
  z-index: 20;
  border-radius: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 40px;
  position: relative;
  bottom: 30px;
  &-yueka {
    width: 92%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    .bg {
      width: 160px;
      height: 112px;
      background-image: url("../assets/vip2.png");
      background-repeat: no-repeat;
      background-size: contain;
      border-radius: 10px;
      margin: 10px 4px 0;
      border: 2px solid #cceaf100;
      position: relative;
      text-align: center;
    }
    &-num {
      position: absolute;
      top: 58px;
      left: 0px;
      right: 10px;
      margin: auto;
      font-size: 28px;
    }
    &-year {
      position: absolute;
      top: 25px;
      left: 8px;
      font-size: 14px;
    }
    img {
      width: 80px;
    }
  }

  & > img {
    width: 50%;
    margin: auto;
    padding: 20px 0 10px;
  }
}
</style>
