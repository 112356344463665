var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"minehead"},[_c('div',{staticClass:"changePhone",on:{"click":_vm.toLogin}},[_vm._v("切换账号")]),_c('img',{staticClass:"headPic",attrs:{"src":require("../assets/headPic.png")},on:{"click":_vm.toUserMes}}),_c('div',{staticClass:"phoneNum"},[_vm._v(_vm._s(_vm.userMes.phone || "尚未登录"))])]),_c('img',{staticStyle:{"width":"94%","margin":"10px auto"},attrs:{"src":require("../assets/banner2.png")},on:{"click":_vm.toequity}}),_c('div',{staticClass:"mineList"},[(_vm.vipType != 0)?_c('div',{staticClass:"eachBlock",on:{"click":_vm.mineVip}},[_vm._m(0),_c('span',{staticClass:"eachTit"},[_vm._v("会员")]),_c('img',{staticClass:"arrow",attrs:{"src":require("../assets/arrow1.png")}})]):_vm._e(),_c('div',{staticClass:"eachBlock",on:{"click":_vm.toCar}},[_vm._m(1),_c('span',{staticClass:"eachTit"},[_vm._v("购物车")]),_c('img',{staticClass:"arrow",attrs:{"src":require("../assets/arrow1.png")}})]),_c('div',{staticClass:"eachBlock",on:{"click":_vm.toMineOrder}},[_vm._m(2),_c('span',{staticClass:"eachTit"},[_vm._v("订单")]),_c('img',{staticClass:"arrow",attrs:{"src":require("../assets/arrow1.png")}})]),_c('div',{staticClass:"eachBlock",on:{"click":_vm.toAddress}},[_vm._m(3),_c('span',{staticClass:"eachTit"},[_vm._v("地址")]),_c('img',{staticClass:"arrow",attrs:{"src":require("../assets/arrow1.png")}})]),(_vm.isShowService)?_c('div',{staticClass:"eachBlock",on:{"click":_vm.toService}},[_vm._m(4),_c('span',{staticClass:"eachTit"},[_vm._v("客服")]),_c('img',{staticClass:"arrow",attrs:{"src":require("../assets/arrow1.png")}})]):_vm._e(),(_vm.vipType != 0)?_c('div',{staticClass:"eachBlock",on:{"click":_vm.toCdKey}},[_vm._m(5),_c('span',{staticClass:"eachTit"},[_vm._v("卡密兑换")]),_c('img',{staticClass:"arrow",attrs:{"src":require("../assets/arrow1.png")}})]):_vm._e()]),_c('FootTab')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"eachIco"},[_c('img',{staticStyle:{"width":"0.44rem"},attrs:{"src":require("../assets/mineIco1.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"eachIco"},[_c('img',{staticStyle:{"width":"0.45rem"},attrs:{"src":require("../assets/mineIco2.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"eachIco"},[_c('img',{staticStyle:{"width":"0.36rem"},attrs:{"src":require("../assets/mineIco3.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"eachIco"},[_c('img',{staticStyle:{"width":"0.39rem"},attrs:{"src":require("../assets/mineIco4.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"eachIco"},[_c('img',{staticStyle:{"width":"0.44rem"},attrs:{"src":require("../assets/mineIco5.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"eachIco"},[_c('img',{staticStyle:{"width":"0.64rem"},attrs:{"src":require("../assets/mineIco6.png")}})])
}]

export { render, staticRenderFns }